:root {
  --text: #495057;
  --first-color: #4f2d7f;
  --second-color: #1e70cd;
  --second-color-alt: #9aa3b5;
  --thrity-color: linear-gradient(90deg, rgba(0,81,187,1) 0%, rgba(0,151,252,1) 74%);;
  --thrity-color-alt: #d4d4d5;
  --white-color: #ffffff;
  --error-color: #ff3b3b;
  --success-color: #5cb85c;
  --warn-color: #f0ad4e;
  --info-color: #5bc0de;
  --fs-title: 1.313rem;
  --fs-text: 0.894rem;
  --input-disabled: #f1f1f2;
  --border-color: #e5e5e5;
}

*,
*::before,
*::after {
  outline: none !important;
}

*::-webkit-scrollbar {
  width: 16px;
  height: 18px;
  background: #f7f5f5;
}

*::-webkit-scrollbar-thumb {
  background: var(--first-color);
  border: 5px solid #f7f5f5;
  border-radius: 8px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-handle-color: #73b72b;
  --mdc-switch-selected-focus-handle-color: #73b72b;
  --mdc-switch-selected-hover-handle-color: #73b72b;
  --mdc-switch-selected-pressed-handle-color: #73b72b;
  --mdc-switch-selected-focus-state-layer-color: #a8eb60;
  --mdc-switch-selected-hover-state-layer-color: #a8eb60;
  --mdc-switch-selected-pressed-state-layer-color: #a8eb60;
  --mdc-switch-selected-focus-track-color: #a8eb60;
  --mdc-switch-selected-hover-track-color: #a8eb60;
  --mdc-switch-selected-pressed-track-color: #a8eb60;
  --mdc-switch-selected-track-color: #a8eb60;

  --mdc-switch-handle-width: 22px;
  --mdc-switch-handle-height: 22px;
  --mdc-switch-track-width: 42px;
  --mdc-switch-track-height: 16px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-state-layer-size: max-content;
  --mdc-switch-track-shape: 9px;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080;
}

.mat-tab-header {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.mat-tab-label-container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  z-index: 1;
}

.mat-tab-list {
  flex-grow: 1;
  position: relative;
  transition: transform 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.mat-tab-label {
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 0.6;
  min-width: 150px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;

  &.mat-tab-label-active {
    color: var(--first-color);
    font-weight: bold;
    opacity: 1;
  }
}

.mat-ink-bar {
  height: 3px !important;
}

.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  font-size: 0.894rem;
}

.mat-ink-bar {
  position: absolute;
  bottom: 0;
  height: 2px;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--first-color);
}

.mat-ripple-element {
  display: none !important;
}

.mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.mat-tab-body {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  flex-basis: 100%;
}

.mat-tab-body.mat-tab-body-active {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
  flex-grow: 1;
}

.mat-tab-body-content {
  width: 100%;
  padding: 1rem 0;
  overflow-x: hidden !important;
}

// DATEPICKER

.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: var(--white-color);
  box-shadow: 0 0 10px 0 var(--thrity-color-alt);
  position: relative;
  z-index: 1;
}

bs-datepicker-container {
  padding: 15px;
}

.bs-datepicker .bs-media-container {
  display: flex;
}

body bs-datepicker-container .bs-datepicker-head {
  background-color: var(--first-color);
}

.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
}

.bs-datepicker-head:after {
  content: '';
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: 0 0;
  text-align: center;
  cursor: pointer;
  color: var(--white-color);
  transition: 0.3s;
}

.bs-datepicker-head button.next,
.bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
}

.bs-datepicker-head button.next,
.bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid var(--thrity-color-alt);
}

.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}

.bs-datepicker-body table th {
  font-size: 13px;
  color: var(--second-color-alt);
  font-weight: 400;
  text-align: center;
}

.bs-datepicker-body table td {
  color: var(--thrity-color);
  text-align: center;
  position: relative;
  padding: 0;
}

.bs-datepicker-body table td span:before,
.bs-datepicker-body table td:before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -2px;
  right: -2px;
  box-sizing: content-box;
  background: 0 0;
}

.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  border-radius: 50%;
  position: relative;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.theme-green .bs-datepicker-body table td.week span {
  color: var(--first-color);
}

.bs-datepicker-body table td span:before,
.bs-datepicker-body table td:before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -2px;
  right: -2px;
  box-sizing: content-box;
  background: 0 0;
}

.bs-datepicker-body table td span.is-other-month,
.bs-datepicker-body table td.is-other-month span {
  color: rgba(0, 0, 0, 0.25);
}

.bs-datepicker-body table td span:before,
.bs-datepicker-body table td:before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -2px;
  right: -2px;
  box-sizing: content-box;
  background: 0 0;
}

.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
  cursor: pointer;
}

.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected),
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span {
  background-color: var(--thrity-color-alt);
  transition: 0s;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td span[class*='select-']:after,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td[class*='select-'] span:after,
.theme-green .bs-datepicker-head {
  background: var(--first-color);
  color: var(--white-color);
}

.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.bs-datepicker-body table.months td span {
  padding: 6px;
  border-radius: 15px;
}

.bs-datepicker-body table.months td {
  height: 52px;
}

.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 45px;
  margin: 0 auto;
}

// END CALENDAR DATAPICKER

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-pane {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  pointer-events: auto;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
}

.mat-tooltip-panel {
  pointer-events: none !important;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tooltip {
  color: var(--white-color);
  border-radius: 4px;
  margin: 14px;
  max-width: 250px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-global-overlay-wrapper {
  overflow-y: hidden;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
}

.mat-dialog-container {
  display: block;
  padding: 0 !important;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.mdl-card,
.mdl-layout__drawer,
.mdl-data-table,
.mat-dialog-container,
.table-pag {
  background: var(--barra) !important;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  background: var(--white-color);
  color: rgba(0, 0, 0, 0.87);
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@media (min-width: 720px) {

  .ngb-carousel,
  .dropdown-item,
  .ngb-datepicker,
  .ngb-rating {
    outline: 0 !important;
  }
}

.layout-wrapper {
  overflow: hidden;
}

.router-transitions {
  opacity: 1;
  transition: opacity 0.2s;

  body.app-loading & {
    opacity: 0.75;
  }
}

@include keyframes(ngFloatingElementAnimation) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.default-style,
.material-style {

  .tooltip,
  .popover {
    animation: ngFloatingElementAnimation 0.25s;
  }

  .dropdown-menu {
    animation: ngFloatingElementAnimation 0.1s;
  }
}

@include keyframes(ngModalBackdropAnimation) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

@include keyframes(ngMaterialModalBackdropAnimation) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}

@include keyframes(ngDefaultModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(0.8);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@include keyframes(ngTopModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(ngSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(ngRtlSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(ngFillInModalAnimation) {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.ngb-modal-backdrop.modal-backdrop {
  opacity: 0.5;
  animation: ngModalBackdropAnimation 0.15s ease-out;
}

.ngb-modal-window {
  &.modal .modal-dialog {
    animation: ngDefaultModalAnimation 0.15s ease-out;
  }

  &.modal-top .modal-dialog {
    animation: ngTopModalAnimation 0.15s ease-out;
  }

  &.modal-slide .modal-dialog {
    animation: ngSlideModalAnimation 0.15s ease-out;
  }

  &.modal-fill-in .modal-dialog {
    animation: ngFillInModalAnimation 0.15s ease-out;
  }

  [dir='rtl'] &.modal-slide .modal-dialog {
    animation: ngRtlSlideModalAnimation 0.15s ease-out;
  }
}

.material-style .ngb-modal-backdrop.modal-backdrop {
  opacity: 0.2;
  animation: ngMaterialModalBackdropAnimation 0.15s ease-out;
}

[dir='rtl'] .dz-hidden-input {
  right: 0 !important;
  left: auto !important;
}

.ngb-accordion .card-header .btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  border: 0;
  font-size: var(--fs-text);
}

.ngb-accordion-with-icon .card-header .btn.btn-link {
  display: flex;
  justify-content: space-between;
}

.ngb-typeahead-window .dropdown-item {
  cursor: pointer;
}

table.dataTable tbody>tr.selected,
table.dataTable tbody>tr>.selected {
  background-color: var(--thrity-color) !important;
}

.btn-csv i {
  color: var(--first-color);
}

.btn-csv {
  border: 0 !important;
  background: rgba(240, 248, 255, 0);
  background-image: linear-gradient(to bottom, white 0%, var(--white-color) 100%) !important;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

//Ngx bootstrap datepicker custom color
body bs-datepicker-container .bs-datepicker-head {
  background-color: #7fc042;
}

@font-face {
  font-family: 'Muli';
  src: url(assets/font/Muli-Regular.woff) format('opentype');
}

.card-header h5 {
  font-weight: bold !important;
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

.dte-section-header {
  font-size: var(--fs-title);
  font-weight: 700;
  color: var(--thrity-color);
}

.dte-input-label {
  font-size: 0.83rem;
  font-weight: 500;
  color: var(--thrity-color);
}

.dte-base-text {
  font-size: var(--fs-text);
  font-weight: 400;
  color: var(--thrity-color);
}

.navbar-app {
  position: relative;
  padding: 0.5rem 0 !important;

  &--color {
    padding: 0.5rem 1rem !important;
    background: var(--thrity-color);
  }
}

.navbar.bg-white .navbar-nav .nav-link {
  font-size: var(--fs-text);
  color: var(--thrity-color-alt) !important;
  user-select: none;
  cursor: default !important;
}

.navbar.bg-white .navbar-nav .nav-link:hover,
.navbar.bg-white .navbar-nav .nav-link:focus {
  color: var(--white-color) !important;
}

.navbar.bg-white .navbar-nav .show>.nav-link,
.navbar.bg-white .navbar-nav .active>.nav-link,
.navbar.bg-white .navbar-nav .nav-link.show,
.navbar.bg-white .navbar-nav .nav-link.active {
  color: var(--white-color) !important;
}

.navbar.bg-white .navbar-nav .nav-link:focus~.dropdown-menu {
  display: block !important;
}

.title-option {
  color: var(--first-color) !important;
  margin-bottom: 0 !important;
  cursor: default !important;
  font-size: var(--fs-text) !important;
}

.subtitle-option {
  color: var(--thrity-color) !important;
  font-size: var(--fs-text) !important;
}

.navbar.bg-white .navbar-nav .nav-link.cursor-pointer,
.cursor-pointer {
  user-select: none;
  cursor: pointer !important;
}

html:not([dir='rtl']) .p-2-5 {
  padding: 0.75rem !important;
}

html:not([dir='rtl']) .pr-lg-4-5,
html:not([dir='rtl']) .px-lg-4-5 {
  padding-right: 1.75rem !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  appearance: none !important;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

.fec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.link-app {
  color: var(--first-color) !important;
  text-decoration: none;

  &:target {
    color: var(--first-color) !important;
  }

  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--first-color) !important;
  }

  &--icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

html,
body {
  font-family: 'Muli', sans-serif !important;
  overflow-x: hidden;
}

body {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.ngx-datatable .datatable-header {
  height: max-content !important;
  background: var(--first-color);
}

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  height: inherit;
  background: var(--first-color);
  color: var(--white-color);
  padding: 0.625rem;
  text-align: center;
  font-size: var(--fs-text);
}

.datatable-body-cell-label {
  padding: 0.625rem;
  vertical-align: top;
  color: var(--thrity-color);
  text-align: center;
  font-size: var(--fs-text);
}

.datatable-row-wrapper {
  border-top: 1px solid #e8e8e9;
}

.datatable-row-wrapper:first-child {
  border-top: none;
}

.ngx-datatable .datatable-body .datatable-body-row>div:hover {
  color: var(--thrity-color);
  background-color: rgba(24, 28, 33, 0.035);
}

.title-app {
  font-size: var(--fs-title) !important;
  font-weight: bold !important;
  margin: 0;
  letter-spacing: 0.045rem !important;
  text-transform: capitalize !important;

  &--table {
    font-size: 1rem !important;
    padding: 0;
    margin: 0;
  }
}

.table-app .empty-row {
  width: 100%;
  height: 150px;
  line-height: 150px;
  text-align: center;
  font-weight: bold;
  color: var(--thrity-color-alt);
  font-size: var(--fs-text);
}

.container {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.tools {
  position: relative;
  width: max-content;
  height: 100%;
  margin: 0 auto;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &__item,
  .button-app {
    display: block;
    width: 38px;
    height: max-content;
    padding: 0.188rem 0.6875rem;
    line-height: 1.5;
    border-radius: 0;
    font-size: var(--fs-text) !important;
    background: var(--white-color);
    color: var(--thrity-color);
    border: 0 solid var(--thrity-color-alt);
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    cursor: pointer;

    &.no-default {
      color: var(--thrity-color-alt);
    }

    &.ion-md-add {
      background: var(--first-color);
      border-color: var(--first-color) !important;
      color: var(--white-color);

      &:hover {
        background: var(--first-color) !important;
      }
    }

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-left-width: 1px;
    }

    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &:hover {
      background: #e3e3e3;
    }
  }

  .button-app {
    width: max-content;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--white-color);
  background-clip: border-box;
  border: 1px solid rgba(24, 28, 33, 0.06) !important;
  border-radius: 0.25rem !important;
  margin-bottom: 1rem;

  .card-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.875rem 1.5rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid rgba(24, 28, 33, 0.06);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

    .icon-close {
      position: absolute;
      top: 50%;
      right: 1.35rem;
      transform: translate(0, -50%);
      font-size: 1.8rem;
      color: var(--thrity-color);
      cursor: pointer;
    }

    .tools {
      margin: 0;
      border: none;

      .form-control {
        margin-right: 0.5rem;
      }

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 40px;
        line-height: 39px !important;
        border-radius: 0.25rem;
        padding: 0;
        margin: 0 0.25rem;
        border: 1px solid var(--thrity-color-alt);

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.15);
        }
      }
    }

    &.fondo-green {
      background: var(--first-color);

      .icon-close,
      .title-app {
        color: var(--white-color);

        .text-muted {
          color: var(--white-color) !important;
          opacity: 0.85;
          font-weight: bold !important;
        }
      }
    }
  }

  .card-dividir {
    width: 100%;
  }
}

.form-control {
  display: block !important;
  width: 100% !important;
  height: calc(1.54em + 0.876rem + 2px) !important;
  padding: 0.438rem 0.875rem !important;
  font-size: var(--fs-text) !important;
  font-weight: 400 !important;
  line-height: 1.54 !important;
  color: var(--text) !important;
  background-color: var(--white-color) !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(24, 28, 33, 0.1) !important;
  border-radius: 0.25rem !important;
  margin: 0 !important;

  &::-ms-reveal {
    display: none !important;
  }

  &:disabled,
  &[readonly] {
    background-color: var(--input-disabled) !important;
    opacity: 1;
  }
}

.custom-select:focus,
.form-control:focus {
  border-color: #7fc042 !important;
  box-shadow: none !important;
}

.input-group {
  &.input-group-nowrap {
    flex-wrap: nowrap;

    .form-control {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  >.form-control {
    display: block !important;
    width: 100% !important;
    height: calc(1.54em + 0.876rem + 2px) !important;
    padding: 0.438rem 0.875rem !important;
    font-size: var(--fs-text) !important;
    font-weight: 400 !important;
    line-height: 1.54 !important;
    color: var(--text) !important;
    background-color: var(--white-color) !important;
    background-clip: padding-box !important;
    border: 1px solid rgba(24, 28, 33, 0.1) !important;
    border-radius: 0.25rem !important;
  }
}

:not(.input-group)>.form-control {
  display: block !important;
  width: 100% !important;
  height: calc(1.54em + 0.876rem + 2px) !important;
  padding: 0.438rem 0.875rem !important;
  font-size: var(--fs-text) !important;
  font-weight: 400 !important;
  line-height: 1.54 !important;
  color: var(--text) !important;
  background-color: var(--white-color) !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(24, 28, 33, 0.1) !important;
  border-radius: 0.25rem !important;

  &:disabled,
  &[readonly] {
    background-color: var(--input-disabled) !important;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

.btn {
  text-transform: none !important;
  border: 1px solid transparent !important;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  margin: 3rem 0 2rem;

  &__notcopy {
    text-decoration: none;
    color: var(--first-color);
    font-size: var(--fs-text);
    margin: 0;
    padding: 0;
    cursor: pointer;

    &:hover,
    &:target,
    &:active,
    &:focus {
      text-decoration: none;
      color: var(--first-color);
    }
  }

  &__version {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.25rem 0 0;
    padding: 0;
    color: var(--thrity-color);
    opacity: 0.65;
    font-size: var(--fs-text);
    font-weight: bold;
    user-select: none;

    img {
      display: block;
      margin: 0;
      padding: 0;
      margin-right: 0.25rem;
      height: 18px;
    }
  }
}

.carousel-item {
  img {
    object-fit: cover;
    object-position: center center;
  }
}

.button {
  display: inline-block;
  font-weight: 400;
  color: var(--thrity-color);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--white-color);
  border: 1px solid var(--thrity-color-alt);
  padding: 0.438rem 1.125rem;
  font-size: var(--fs-text);
  line-height: 1.54;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;
  margin: 0 0.5rem;
  outline: none !important;
  text-transform: capitalize !important;

  &--primario {
    border-color: transparent;
    background: var(--first-color);
    color: var(--white-color);
  }

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }

  &:disabled {
    background: rgba(0, 0, 0, 0.15) !important;
    color: var(--second-color-alt) !important;
    border-color: transparent !important;
    cursor: not-allowed;

    &:hover {
      background: rgba(0, 0, 0, 0.15) !important;
    }
  }
}

.button-footer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2rem 0 0;

  .button {
    &:last-child {
      margin-right: 0;
    }
  }
}

.ngx-pagination {
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;

  li {

    a,
    &.current {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      line-height: 40px;
      padding: 0;
      color: var(--thrity-color);
      background-color: transparent;
      border: 1px solid var(--thrity-color-alt);
      margin: 0 1px;
      outline: none !important;

      &:focus {
        outline: none;
      }
    }

    &.current {
      border-color: var(--first-color);
      background-color: var(--first-color);
      color: var(--white-color);
    }
  }
}

.ngx-pagination .pagination-previous.disabled::before,
.ngx-pagination .pagination-previous a::before {
  content: '‹' !important;
  margin: 0 !important;
  font-size: 1.5rem;
  padding-bottom: 5px;
}

.ngx-pagination .pagination-next.disabled::after,
.ngx-pagination .pagination-next a::after {
  content: '›' !important;
  margin: 0 !important;
  font-size: 1.5rem;
  padding-bottom: 5px;
}

.cdk-overlay-pane {
  .card-body {
    height: max-content;
    max-height: 90vh;
    overflow-y: auto;
  }
}

.ModalTable.cdk-overlay-pane {
  width: 100%;
  max-width: 98% !important;

  .card {
    margin: 0;
    border: none !important;
  }
}

.ModalForm.cdk-overlay-pane {
  width: 100%;
  max-width: 65% !important;

  .card {
    margin: 0;
    border: none !important;
  }
}

.no-display {
  display: none !important;
}

label.form-control.file {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  border: none !important;
  cursor: pointer;

  .button {
    margin-left: 0 !important;
    white-space: nowrap;
  }

  .value {
    margin: 0;
    font-size: var(--fs-text);
    color: var(--thrity-color);
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

select.form-control {
  cursor: pointer !important;
}

textarea.form-control {
  width: 100%;
  min-height: 140px !important;
  max-height: 140px !important;
  resize: none !important;
}

.dropdown-item:not(.disabled).active,
.dropdown-item:not(.disabled):active {
  background: var(--first-color) !important;
  color: var(--white-color) !important;
}

.dropdown-item.no-active:active {
  background: rgba(24, 28, 33, 0.03) !important;
  color: var(--thrity-color) !important;
}

.color-red {
  color: #d9534f !important;
}

.card .text-primary {
  color: var(--first-color) !important;
}

.swal2-icon {
  width: 4rem !important;
  height: 4rem !important;
}

.swal2-popup .swal2-styled.swal2-confirm,
.swal2-popup .swal2-styled.swal2-cancel {
  border: 1px solid transparent !important;
  background-color: var(--first-color) !important;
  font-size: var(--fs-text) !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0.438rem 1.125rem !important;
}

.swal2-popup .swal2-styled.swal2-cancel {
  background-color: var(--white-color) !important;
  border: 1px solid var(--thrity-color-alt) !important;
  color: var(--thrity-color) !important;
}

.swal2-popup .swal2-title {
  font-size: var(--fs-title) !important;
  font-weight: 400 !important;
}

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='tip'] {
  top: 2.4em !important;
  left: 0.575em !important;
}

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='long'] {
  top: 2em !important;
  right: 0.4em !important;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  top: 2rem !important;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
  left: 0.6rem !important;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
  right: 0.6rem !important;
}

.swal2-icon::before {
  height: 100% !important;
  font-size: 2.75em !important;
}

.checkbox-app {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--thrity-color);
  cursor: pointer;

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 1px solid var(--first-color);
    border-radius: 0.25rem;
    background: var(--white-color);

    .ion {
      padding-top: 2px;
      display: none;
      color: var(--first-color);
    }
  }
}

input[type='checkbox']:checked~.checkbox-app .box .ion {
  display: block;
}

input[type='radio']:checked~.checkbox-app .box .ion {
  display: block;
}

.icon-active {
  display: inline-block;
  color: var(--white-color);
  font-size: 0.75rem;
  opacity: 0.55;
  transition: all 0.15s ease-in-out;
}

.navbar .nav-link.TargetActive .icon-active {
  color: var(--first-color) !important;
  opacity: 1 !important;
}

.default-style .sidenav .app-brand.demo {
  height: 66px;
}

.material-style .sidenav .app-brand.demo {
  height: 70px;
}

.dropdown-item:not(.disabled).active,
.dropdown-item:not(.disabled):active {
  background: transparent !important;
  color: var(--first-color) !important;
}

.navbar-nav .nav-link.padding-left {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}

.dropdown-item.subtitle-option>span {
  color: var(--thrity-color) !important;
}

.minmax-height {
  .table-app--tbody {
    min-height: 250px;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.maxcontent {
  height: 325px;
  min-height: 325px;
  max-height: 325px;
}

// NO DAR ESTILOS DEBAJO DE ESTO
body.zoom-init-82 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  // zoom: 0.82;
  z-index: 1;

  @media only screen and (max-width: 996px) {
    // zoom: 0.95;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  margin: 0 !important;
}

.dropdown-item {
  white-space: pre-wrap !important;
}

.form-label,
.col-form-label {
  margin-bottom: 0.5rem !important;
}

.inline-block {
  display: inline-block !important;
  width: max-content;
}

.fcc {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.form-group {
  &--button {
    position: relative;

    input,
    select,
    textarea {
      &.form-control {
        width: calc(100% - (38px + 0.75rem)) !important;
      }
    }

    .button {
      width: 38px;
      height: 36px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute !important;
      right: 0;
      bottom: 2px;
      margin: 0 !important;
    }
  }
}

.zback_nav {
  z-index: 1 !important;
}

.layout-navbar {
  box-shadow: none !important;
}

.semaforo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--second-color-alt);

  &--question {
    background: #ecf016;
  }

  &--warning {
    background: #ffc400;
  }

  &--danger {
    background: #e20719;
  }

  &--info {
    background: #08b2d8;
  }

  &--success {
    background: #0ddd41;
  }
}

.editor-txt {
  .cke_chrome {
    margin-bottom: 1rem !important;
  }

  .cke_toolbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}

.button-file-load {
  width: 116px;
  height: max-content;

  label,
  button {
    margin: 0;
  }
}

.mat-dialog-container {
  overflow: hidden !important;
}

.progress-bar-app {
  width: 100%;
  height: 20px;
  border-radius: 50px;
  border: 1px solid var(--thrity-color-alt);
  background: rgb(243, 243, 243);
  overflow: hidden;

  .load {
    background: var(--first-color);
    background-size: 1rem 1rem;
    width: 0;
    height: 100%;

    &--pendiente {
      width: 100%;
      background: linear-gradient(-45deg,
          var(--thrity-color-alt) 30%,
          transparent 30%,
          transparent 60%,
          var(--thrity-color-alt) 60%,
          var(--thrity-color-alt) 90%,
          transparent 90%,
          transparent);
      background-size: 54px 36px;
      background-position-x: 1px;
      animation: loading 55s infinite linear;
    }
  }

  @for $i from 1 through 101 {
    .load--#{$i} {
      width: #{$i}+'%';
    }
  }
}

@keyframes loading {
  from {
    background-position-x: 1px;
  }

  to {
    background-position-x: calc(45px * 100);
  }
}

.app-root,
.cdk-visually-hidden,
.cdk-overlay-container,
.swal2-container.swal2-shown,
bs-datepicker-container,
bs-daterangepicker-container {
  zoom: 80%;
}

.dropdown-menu {
  left: initial !important;
  right: 0 !important;
  transform-origin: top right !important;
}

[data-tooltip] {
  --x: 0;
  --y: 0;
  position: relative !important;

  &::after {
    display: none;
    content: attr(data-tooltip);
    font-family: 'Muli', Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
    position: fixed;
    top: var(--y);
    right: var(--x);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding: 0.5rem 1rem 0.5rem 0.75rem;
    font-size: 0.894rem;
    color: var(--white-color);
    background: var(--thrity-color);
    border-radius: 50px 0 50px 50px;
    font-weight: normal;
    text-transform: capitalize !important;
    z-index: 10;

    &:hover {
      display: none;
    }
  }

  &:hover {
    &::after {
      display: none;
    }
  }
}

.box-ultimate-update {
  display: block;
  width: 100%;
  height: max-content;
  margin-bottom: 1rem;

  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: .25rem;
    color: var(--thrity-color);
    background: rgba(0, 0, 0, .015);
    margin-bottom: .5rem;
    padding: .5rem .75rem;

    p {
      margin: 0;

      &.title {
        user-select: none;
      }
    }

    &:last-child {
      margin: 0;
    }
  }
}

.container-fluid.container-p-y:not([class^=pb-]):not([class*=" pb-"]) {
  padding-top: 0 !important;
}

.authentication-wrapper {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;

  .row.carousel.slide,
  .app-carousel,
  .vh-100 {
    height: 100% !important;
  }

  .carousel-inner {
    height: 100% !important;
  }
}

.mat-mdc-snack-bar-container {
  color: white;

  &.error {
    background: var(--error-color) !important;
  }

  &.success {
    background: var(--success-color) !important;
  }

  &.warn {
    background: var(--warn-color) !important;
  }

  &.info {
    background: var(--info-color) !important;
  }
}

.app-root {
  .vh-100 {
    height: 100% !important;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.form-group {
  label.form-label {
    text-transform: initial !important;
  }
}

.ngx-recaptcha2 {
  >div>div>div {
    width: 378px !important;
    height: 78px !important;

    iframe {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
}

@import './demo';

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

// Remove outline on desktops
@media (min-width: 720px) {

  ngb-carousel,
  .dropdown-item,
  ngb-datepicker,
  ngb-rating {
    outline: 0 !important;
  }
}

// Fix horizontal scrollbar issue when .dropdown-menu-right element is shown
.layout-wrapper {
  overflow: hidden;
}

// *******************************************************************************
// * Page transition

.router-transitions {
  opacity: 1;
  transition: opacity 0.2s;

  body.app-loading & {
    opacity: 0.75;
  }
}

// *******************************************************************************
// * Animations

@include keyframes(ngFloatingElementAnimation) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.default-style,
.material-style {

  .tooltip,
  .popover {
    animation: ngFloatingElementAnimation 0.25s;
  }

  .dropdown-menu {
    animation: ngFloatingElementAnimation 0.1s;
  }
}

// *******************************************************************************
// * Modal animations

@include keyframes(ngModalBackdropAnimation) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

@include keyframes(ngMaterialModalBackdropAnimation) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}

@include keyframes(ngDefaultModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(0.8);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@include keyframes(ngTopModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(ngSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(ngRtlSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(ngFillInModalAnimation) {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

ngb-modal-backdrop.modal-backdrop {
  opacity: 0.5;
  animation: ngModalBackdropAnimation 0.15s ease-out;
}

ngb-modal-window {
  &.modal .modal-dialog {
    animation: ngDefaultModalAnimation 0.15s ease-out;
  }

  &.modal-top .modal-dialog {
    animation: ngTopModalAnimation 0.15s ease-out;
  }

  &.modal-slide .modal-dialog {
    animation: ngSlideModalAnimation 0.15s ease-out;
  }

  &.modal-fill-in .modal-dialog {
    animation: ngFillInModalAnimation 0.15s ease-out;
  }

  [dir='rtl'] &.modal-slide .modal-dialog {
    animation: ngRtlSlideModalAnimation 0.15s ease-out;
  }
}

.material-style ngb-modal-backdrop.modal-backdrop {
  opacity: 0.2;
  animation: ngMaterialModalBackdropAnimation 0.15s ease-out;
}

// Prevent horizontal scrollbar
[dir='rtl'] .dz-hidden-input {
  right: 0 !important;
  left: auto !important;
}

// *******************************************************************************
// * Accordion

ngb-accordion .card-header .btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  border: 0;
  font-size: 0.894rem;
}

.ngb-accordion-with-icon .card-header .btn.btn-link {
  display: flex;
  justify-content: space-between;
}

// *******************************************************************************
// * Typeahead

ngb-typeahead-window .dropdown-item {
  cursor: pointer;
}

table.dataTable tbody>tr.selected,
table.dataTable tbody>tr>.selected {
  background-color: #7ec04230 !important;
}

.btn-csv i {
  color: #7fc042;
}

.btn-csv {
  border: 0 !important;
  background: rgba(240, 248, 255, 0);
  background-image: linear-gradient(to bottom, white 0%, var(--white-color) 100%) !important;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

//Ngx bootstrap datepicker custom color
body bs-datepicker-container .bs-datepicker-head {
  background-color: #7fc042;
}

@font-face {
  font-family: Muli;
  src: url(assets/font/Muli-Regular.woff) format('opentype');
}

.card-header h5 {
  font-weight: bold !important;
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

.dte-section-header {
  font-size: 1.313rem;
  font-weight: 700;
  color: #4f5255;
}

.dte-input-label {
  font-size: 0.83rem;
  font-weight: 500;
  color: #4f5255;
}

.dte-base-text {
  font-size: 0.894rem;
  font-weight: 400;
  color: #4f5255;
}

.columns-app {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: max-content;

  &__items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc((100% / 3) - 1rem);
    height: 40px;
    background: rgba(0, 0, 0, .05);
    border-radius: .5rem;
    margin: .5rem;
    padding: 0 .75rem;

    label {
      margin: 0 !important;
    }
  }
}

.switcher .switcher-indicator {
  cursor: pointer !important;
}

table.table th {
  border-right: 1px solid white !important;
  white-space: nowrap !important;

  &:last-child {
    border-right: none !important;
  }
}

tag-input {
  overflow-x: hidden !important;
}

.ng2-tag-input {
  border: 1px solid #efefef;
  border-radius: .25rem !important;
  padding: 0 .5rem !important;
}

.ng2-tag-input__text-input {
  width: 100% !important;
}

tag-ripple {
  background: transparent !important;
}

.ngx-chips-primary[class] .ng2-tag-input tag delete-icon svg path {
  fill: var(--thrity-color) !important;
}

.ngx-chips-primary[class] .ng2-tag-input tag {
  background: transparent !important;
  color: var(--first-color) !important;
  box-shadow: none !important;
}


.navbar.color-dark-nav {
  background: var(--gray-dark) !important;
}

.navbar-nav .nav-link.padding-left {
  color: var(--white-color) !important;
}

.wrapper-layout {
  --wNav: 300px;

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  background: #f7f5f5;
  overflow: hidden;
  z-index: 1;

  &--content,
  &--navbar {
    width: calc(100% - var(--wNav));
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    background: transparent;
    overflow: hidden;
    transition: all .15s ease-in-out;
  }

  &--navbar {
    position: relative;
    width: var(--wNav);
    background: var(--white-color);
    transform: translateX(0);

    &.no-nav {
      width: 0;
      transform: translateX(-100%);
    }
  }

  &--content {
    .sidebar {
      height: 80px;
      background: var(--thrity-color);
      padding: 0 1rem;
    }

    .router-outlet {
      width: 100%;
      padding: 1rem;
      min-height: calc(100% - 80px);
      max-height: calc(100% - 80px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  &.fullscreen {
    .wrapper-layout--content {
      width: 100%;
    }
  }
}

.bg-dark-color {
  background: var(--thrity-color) !important;
}

.text-dark-color {
  color: var(--thrity-color) !important;
}

.bg-first-color {
  background: var(--first-color) !important;
}

.text-first-color {
  color: var(--first-color) !important;
}

.bg-second-color {
  background: var(--second-color) !important;
}

.text-second-color {
  color: var(--second-color) !important;
}

[az-tooltip] {
  position: relative !important;
}

[az-tooltip]:hover .az-tooltip {
  display: block !important;
}

.alert {
  position: relative;
  display: block;
  width: 100%;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  font-family: 'Muli', sans-serif;
  font-size: var(--fs-text);
  border: 1px solid transparent;
  border-radius: 5px;
  color: var(--text);
  background-color: transparent;
  border-color: transparent;

  &--info {
    color: var(--info-color);
    background-color: #e4faff;
    border-color: #d5f8ff;
  }

  &--success {
    color: var(--success-color);
    background-color: #e4f9f0;
    border-color: #d4f3e5;
  }

  &--warn {
    color: var(--warn-color);
    background-color: #f6eaa7;
    border-color: #ebde98;
  }

  &--error {
    color: var(--error-color);
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }
}

.btn {
  display: block !important;
  width: max-content !important;
  height: max-content !important;
  padding: .5rem .75rem !important;
  border-radius: 10px !important;
  background: var(--white-color) !important;
  border: 1px solid var(--thrity-color) !important;
  color: var(--thrity-color) !important;
}

.button-app, .swal2-styled {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 40px !important;
  line-height: 40px !important;
  margin: 0 !important;
  padding: 0 1rem !important;
  font-size: var(--fs-text) !important;
  font-weight: bold !important;
  background: transparent !important;
  color: var(--first-color) !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;

  .icon {
    margin-right: .5rem;
    font-size: inherit;
    color: inherit;
  }

  &.swal2-cancel {
    display: none !important;
  }

  &--first-color, &.swal2-confirm {
    background: var(--first-color) !important;
    color: var(--white-color) !important;
  }

  &--second-color {
    background: var(--second-color) !important;
    color: var(--white-color) !important;
  }

  &--third-color {
    background: var(--thrity-color) !important;
    color: var(--white-color) !important;
  }

  &--error-color {
    background: var(--error-color) !important;
    color: var(--white-color) !important;
  }

  &.showpass {
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 0 !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    background: var(--first-color) !important;
    color: var(--white-color) !important;

    &__eye {
      font-size: 1rem;
      line-height: 0;
    }
  }

  &:disabled {
    color: rgb(160, 160, 160) !important;
    background: rgba(0, 0, 0, .065) !important;
    cursor: not-allowed !important;
  }
}

.swal2-icon.swal2-icon-show {
  margin-top: 1.5rem !important;
}

.swal2-icon .swal2-icon-content {
  font-size: calc(25px * 2) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--white-color) inset !important;
  -webkit-text-fill-color: var(--text) !important;
}

.bs-datepicker-body table.days span.disabled {
  opacity: .45 !important;
}

.content-table {

  .rows-page,
  .pagination-footer {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    margin-bottom: .75rem;

    .parrafo {
      font-size: var(--fs-text);
      font-weight: normal;
      color: var(--text);
      margin: 0;
      padding: 0;
    }

    .form-group {
      margin: 0 .5rem;

      .form-control {
        width: 90px !important;
        padding-top: 5px;
        padding-bottom: 5px;
        margin: 0;
      }
    }
  }

  .pagination-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: .75rem;

    .paginate {
      display: flex;
      justify-content: center;
      align-items: center;
      width: max-content;

      &-preview,
      &-next {
        display: block;
        padding: 0.5rem;
        font-size: var(--fs-text);
        color: var(--first-color);
        cursor: pointer;

        &.disabled {
          color: var(--input-disabled);
          cursor: not-allowed;
        }
      }

      &-current {
        background: var(--first-color);
        color: var(--white);
        font-size: var(--fs-text);
        font-weight: normal;
        margin: 0 0.5rem;
        text-align: center;
        padding: 0.25rem 0.65rem;
        border-radius: 6px;
        user-select: none;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .rows-page {
      flex-wrap: wrap-reverse;

      >div:first-child {
        margin-top: .75rem;
      }
    }
  }
}

.flex {
  display: flex !important;

  &--column {
    flex-direction: column !important;
  }

  &--wrap {
    flex-wrap: wrap !important;
  }

  .xLeft {
    justify-content: flex-start !important;
  }

  .xRight {
    justify-content: flex-end !important;
  }

  .xCenter {
    justify-content: center !important;
  }

  .xBetween {
    justify-content: space-between !important;
  }

  .xAround {
    justify-content: space-around !important;
  }

  .yLeft {
    align-items: flex-start !important;
  }

  .yRight {
    align-items: flex-end !important;
  }

  .yCenter {
    align-items: center !important;
  }
}

.swal2-actions {
  margin-top: 3rem !important;
}

.swal2-title {
  margin-top: 1rem !important;
}

.selectionCompaniesRoles .table-app .th .checkbox-app {
  display: none !important;
}

.tableRowsSelectChangeDoble .table-app .th .checkbox-app {
  display: none !important;
}

.tableRowsSelectChangeDoble .table-app .table-app--thead {
  display: none !important;
}

.tableRowsSelectChangeDoble .minmax-height .table-app--tbody {
  min-height: 280px;
  max-height: 280px;
}

.button {
  height: 40px !important;
  line-height: 39px !important;
  padding: 0 1.125rem !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.custom-control.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before,
.custom-control.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-control.custom-radio .custom-control-input:checked~.custom-control-label::before {
  border-color: var(--first-color) !important;
  background-color: var(--first-color) !important;
}

.box-radio {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  input[type="radio"] {
    display: none !important;
  }

  &__radio {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: .35rem;
    border-radius: 50px;
    background: var(--white-color);
    border: 1px solid var(--border-color);

    &::before {
      content: "";
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: var(--white-color);
      width: 55%;
      height: 55%;
      border-radius: 50px;
      z-index: 1;
    }
  }

  input[type="radio"]:checked~&__radio {
    background: var(--first-color);
    border-color: var(--first-color);

    &::before {
      display: block;
    }
  }
}

.chips-app {
  display: block;
  width: 100%;
  height: max-content;

  .item {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    height: max-content;
    padding: .5rem .75rem;
    font-size: var(--fs-text);
    color: var(--white-color);
    background: var(--first-color);
    border-radius: 50px;
    margin: .25rem;

    .text {
      margin: 0;
      color: inherit;
      font-size: inherit;
      margin-right: .75rem;
    }

    .delete-chip {
      width: 25px;
      height: 25px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      line-height: 0;
      align-items: center;
      color: var(--first-color);
      background: var(--white-color);
      cursor: pointer;
    }
  }
}

.title-th {
  width: 100% !important;
}

.w-100px {
  width: 100px !important;
}

.w-250px {
  width: 250px !important;
}

.b {
  &l-1 {
    border-left: 1px solid var(--white-color);
  }

  &r-1 {
    border-right: 1px solid var(--white-color);
  }

  &t-1 {
    border-top: 1px solid var(--white-color);
  }

  &b-1 {
    border-bottom: 1px solid var(--white-color);
  }
}

.wrapper-history-reservations-client {
  &--chart {
    width: 100%;
    min-height: 400px;
    display: flex;
  }
}

.modificated {

  .ngx-charts-advanced-pie-chart,
  .ngx-charts-outer {
    width: 100% !important;

    .ngx-charts {
      width: 100% !important;
    }

    >div {
      width: 100% !important;
    }

    .advanced-pie {
      width: 25% !important;
    }

    .advanced-pie-legend-wrapper {
      width: calc(100% - 25%) !important;

      .advanced-pie-legend {
        width: 100% !important;
      }
    }

    @media only screen and (max-width: 767px) {
      .advanced-pie {
        width: 20% !important;
      }

      .advanced-pie-legend-wrapper {
        width: calc(100% - 20%) !important;
      }
    }
  }
}

.item-app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;

  .text {
    font-size: var(--fs-title);
    font-weight: normal;
    color: var(--text);
    margin: 0;
    margin-right: .5rem;
  }

  .cant {
    font-size: var(--fs-title);
    font-weight: bold;
    color: var(--text);
    margin: 0;
  }
}

.maxWidthFromLogin {
  max-width: 450px !important;
  margin: 0 auto !important;
}

.help-error {
  position: relative;
  width: 100%;
  height: max-content;
  margin: .25rem 0 0;
  padding: 0;
  font-size: 12px;
  font-weight: normal;
  color: var(--error-color);
  background: transparent;
}

.icon-fingerprint {
  display: block;
  width: max-content;
  height: max-content;
  margin: 3rem auto;
  font-size: 8rem;
  opacity: .35;
  color: var(--text);
  user-select: none;
}

.toggle-app {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: max-content;

  .title {
    display: block;
    margin: 0;
    padding: 0;
    font-size: inherit;
    color: var(--text);
  }

  .toggle {
    color: var(--text);
    font-size: 25px;

    &.active {
      color: var(--first-color);
    }
  }
}

.opacity-1 {
  opacity: 1 !important;
}

span.tools__item.disabled {
  background: #ddd;
  cursor: not-allowed !important;
}